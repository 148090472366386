import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createVoucher } from "../redux/voucherActions";
import GeneralNavbar from "../components/navbar/GeneralNavbar";
import FooterCard from "../components/card/FooterCard";
import Footer from "../components/footer/Footer";
import ErrorPopup from '../components/ErrorPopup/ErrorPopup';
import Accordion from "../components/accordian/Accordion";
import VoucherDetailCard from "../components/card/VoucherDetailCard";

const loadingOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(5px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
};

function CreateVoucherPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { voucher, loading, error, user_details } = useSelector((state) => state.voucher);
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      createVoucher(voucher, {
        name: e.target.name.value,
        number: e.target.number.value,
        // email: e.target.email.value,
      })
    );
  };

  useEffect(() => {
    if (voucher === null) {
      navigate("/");
    }
  }, [voucher, navigate]);

  useEffect(() => {
    if (error) {
      // navigate("/", { replace: true });
      setTimeout(() => {
        setShowErrorPopup(true);
      }, 500); // Delay the popup to ensure navigation has occurred
    }
  }, [error, navigate]);

  useEffect(() => {
    if (user_details) {
      navigate("/success");
    }
  }, [user_details, navigate]);

  const handlePopupClose = () => {
    setShowErrorPopup(false);
  };

  const item = {
    title: "Got questions ?",
    description: "Check out our Help Center for User Guides and Frequently Asked Questions on how to use the voucher.",
    button_text: "Help Center",
    link: "/help",
  };

  if (voucher === null) {
    return null;
  }

  return (
    <div>
      {loading && (
        <div className="loading-overlay" style={loadingOverlayStyle}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <GeneralNavbar logo={voucher.program.logo} />
      {voucher.program.brandImage ? (
        <div className="banner">

          <img src={voucher.program.brandImage} />
        </div>
      ) : null}

      <div className="create-voucher-page">
        <Row>
          <Col>
            <Row>
              <Col lg={12}>
                <h2>{voucher.program.displayName}</h2>
                <h5>eGift Voucher</h5>
                <Accordion
                  items={[
                    {
                      title: "About",
                      description: voucher.program.description,
                    },
                    {
                      title: "How to use e-Rupi Voucher",
                      description: voucher.program.receiverHowToUseInstructions,
                    },
                    {
                      title: "Terms & Conditions",
                      description: voucher.program.standardTerms,
                    },
                    {
                      title: "Locations",
                      description: "Coming Soon",
                    },
                  ]}
                />
              </Col>
              <Col lg={12}>
                <FooterCard
                  title={item.title}
                  description={item.description}
                  button_text={item.button_text}
                  link={item.link}
                />
              </Col>
            </Row>
          </Col>

          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: " flex-end",
            }}
          >
            <VoucherDetailCard handleSubmit={handleSubmit} voucher={voucher} />
          </Col>
        </Row>
      </div>
      <Footer />
      {/* Error Popup */}
      <ErrorPopup
        show={showErrorPopup}
        message="An error occurred"
        onClose={handlePopupClose}
      />
    </div>
  );
}

export default CreateVoucherPage;
