

import { Col, Row, Container, Image } from "react-bootstrap"
import Accordion from "../components/accordian/Accordion"
import VoucherDetailCard from '../components/card/VoucherDetailCard'
// import usefetchedData from "../hooks/fetchData";
import Card from 'react-bootstrap/Card';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createVoucher } from '../redux/voucherActions';
import GeneralNavbar from '../components/navbar/GeneralNavbar'
import FooterCard from '../components/card/FooterCard.js';
import Footer from "../components/footer/Footer";
import './pages.scss'
import { ReactComponent as SuccessLogo } from '../assests/Group.svg'


function SuccessPage() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { voucher, loading, error, user_details } = useSelector((state) => state.voucher);

    useEffect(() => {
        if (voucher == null) {
            navigate('/');
        }
    },);

    useEffect(() => {
        if (user_details == null) {
            navigate('/');
        }
    }, [user_details, navigate]);


    if (user_details === null | voucher === null) {
        // If localState is null, the component should not render anything because we’re redirecting
        return null;
    }


    return <div>
        <GeneralNavbar logo={voucher.program.logo} />
        {voucher.program.brandImage ? (
            <div className="banner">

                <img src={voucher.program.brandImage} />
            </div>
        ) : null}
        <div className="success-page">
            <h2>{voucher.program.displayName}</h2>
            <h5>eGift Voucher</h5>
            <Row>

                <Card className='success-card'>

                    <Card.Body>
                        <SuccessLogo />
                    </Card.Body>

                    <Card.Body  >
                        {/* <Logo className="logo" /> */}
                        {/* <Card.Title>{title}</Card.Title> */}
                        <div className="confirmation">
                            eRupi Prepaid e-Voucher issued successfully and sent to the UPI app linked with +91 {user_details.number}.

                        </div>


                    </Card.Body>

                    <Card.Body className="border-top bank-logo"  >
                        {/* <Logo className="logo" /> */}
                        {/* <Card.Title>{title}</Card.Title> */}
                        <div className="logo-text">
                            eRupi issued by
                            {/* <Image src={voucher.issuerBank.logo}></Image> */}
                        </div>

                        <div>
                            <Image src="https://saraluat.blr1.cdn.digitaloceanspaces.com/ICICI_Bank_Logo%201.jpg"></Image>
                        </div>



                    </Card.Body>


                </Card>
            </Row>

        </div>
        <Footer />
    </div>
}


export default SuccessPage;