import axios from 'axios';

export const FETCH_VOUCHER_REQUEST = 'FETCH_VOUCHER_REQUEST';
export const FETCH_VOUCHER_SUCCESS = 'FETCH_VOUCHER_SUCCESS';
export const FETCH_VOUCHER_FAILURE = 'FETCH_VOUCHER_FAILURE';

export const CREATE_VOUCHER_REQUEST = 'CREATE_VOUCHER_REQUEST';
export const CREATE_VOUCHER_SUCCESS = 'CREATE_VOUCHER_SUCCESS';
export const CREATE_VOUCHER_FAILURE = 'CREATE_VOUCHER_FAILURE';

export const CLEAR_STORE = "CLEAR_STORE"

export const clearStoreAction = () => ({
    type: 'CLEAR_STORE',
});

export const fetchVoucher = (saral_code, pin, captchaToken) => async (dispatch) => {
    dispatch({ type: FETCH_VOUCHER_REQUEST });
    try {
        const token = await axios.request({
            baseURL: `${window.env.API_URL}` + '/api/v1/auth/login', // Base URL for your API
            timeout: 10000000, // Timeout in milliseconds
            headers: {
                'Content-Type': 'application/json',
            }, // Default headers
            data: {
                "email": window.env.ADMIN_EMAIL,
                "password": window.env.ADMIN_PASSWORD
            },
            method: 'POST'
        }).then(response => {
            console.log(response.data)
            return response.data.tokens.access.token;
        })




        const response = await axios.request({
            baseURL: `${window.env.API_URL}` + '/v1/banking/fetchVoucherDetails', // Base URL for your API
            timeout: 10000000, // Timeout in milliseconds
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,

            }, // Default headers
            data: {
                saralcode: saral_code,
                pin: pin,
                recaptcha: captchaToken
            },
            method: 'POST'
        })

        dispatch({ type: FETCH_VOUCHER_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_VOUCHER_FAILURE, error: error.message });
    }
};

export const createVoucher = (voucherData, user_details) => async (dispatch) => {
    dispatch({ type: CREATE_VOUCHER_REQUEST });
    try {
        const token = await axios.request({
            baseURL: `${window.env.API_URL}` + '/api/v1/auth/login', // Base URL for your API
            timeout: 10000000, // Timeout in milliseconds
            headers: {
                'Content-Type': 'application/json',

            }, // Default headers
            data: {
                "email": window.env.ADMIN_EMAIL,
                "password": window.env.ADMIN_PASSWORD
            },
            method: 'POST'
        }).then(response => {
            console.log(response.data)
            return response.data.tokens.access.token;
        })

        const date = new Date(voucherData.expiryDate)

        const payload = {
            "merchantId": voucherData.program.merchantId.toString(),
            "merchantTranId": voucherData.saralId,
            "subMerchantId": voucherData.program.subMerchantId.toString(),
            "beneficiaryID": user_details.number,
            "mobileNumber": user_details.number,
            "beneficiaryName": user_details.name,
            "amount": voucherData.amount.toString(),
            "expiry": date.toISOString().slice(0, 10),
            "purposeCode": voucherData.program.bankDetails.purposeCode,
            "mcc": voucherData.program.bankDetails.mcc.toString(),
            "VoucherRedemptionType": voucherData.program.redemptionType.toUpperCase(),
            "PayerVA": voucherData.program.bankDetails.PayerVA,
            "type": "CREATE"
        };

        const mandateType = voucherData.program.bankDetails?.mandateType;
        if(mandateType){
            payload["MandateType"] = mandateType
        }

        const payeeVPA = voucherData.program.bankDetails?.payeeVPA;
        if(payeeVPA){
            payload["PayeeVPA"] = mandateType
        }

        const response = await axios.request({
            baseURL: `${window.env.API_URL}` + '/v1/banking/icici/eRupi', // Base URL for your API
            timeout: 10000000, // Timeout in milliseconds
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: payload,
            method: 'POST'
        })

        const data = response.data
        console.log(data)
        if (data.status === "CREATE-SUCCESS") {
            dispatch({ type: CREATE_VOUCHER_SUCCESS, payload: user_details });
        }
        else {
            dispatch({ type: CREATE_VOUCHER_FAILURE, error: data.message });
        }
    } catch (error) {
        dispatch({ type: CREATE_VOUCHER_FAILURE, error: error.message });
    }
};
