import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import "./voucher-detail-card.scss";
import { Link } from "react-router-dom";
import {
  Button,
  CardBody,
  Form,
  InputGroup,
  Row,
  Image,
  Col,
  Container,
} from "react-bootstrap";
// import VoucherImage from "./../../assests/erupi.png";
import PrimaryButton from "../../components/button/PrimaryButton";
import { type } from "@testing-library/user-event/dist/type";

function VoucherDetailCard({ handleSubmit, voucher, formRef }) {
  const [fullName, setFullName] = useState("");
  // const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [checked, setChecked] = useState(false);


  const date = new Date(voucher.expiryDate);

  // Validation function
  const validateForm = (e) => {
    let formErrors = {};
    let isValid = true;

    if (!fullName.trim()) {
      formErrors.fullName = "Full name cannot be empty.";
      isValid = false;
    }

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(email.trim())) {
    //   formErrors.email = "Please enter a valid email address.";
    //   isValid = false;
    // }

    if (!/^\d{10}$/.test(number.trim())) {
      formErrors.number = "Mobile number must be a 10-digit number.";
      isValid = false;
    }

    if (!checked) {
      formErrors.checked = "You must accept the terms and conditions to proceed."
      isValid = false
    }


    if (e.target.checked.value === 'true') {
      e.target.checked.classList.remove('is-invalid')
    }
    else {
      if (!e.target.checked.classList.contains('is-invalid')) {
        e.target.checked.classList.add('is-invalid')
      }

    }


    setErrors(formErrors);
    return isValid;
  };

  // Handle form submission
  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm(e)) {
      try {
        await handleSubmit(e);
      } catch (error) {
        console.error("Error during form submission:", error);
      }
    }
  };

  const card_body = () => {
    if (voucher.activated) {
      return (
        <div className="border-top">
          <CardBody>
            <h2 className="heading">Balance History</h2>
            {voucher.transactions.map((item, index) => {
              const date = new Date(item.created_at);
              return (
                <div key={index}>
                  <Row>
                    <div className="block">
                      <div className="row" style={{ paddingTop: "4%" }}>
                        <div className="col-auto me-auto">
                          <div className="text-style-bold">{item.tenant_name}</div>
                          <div className="text-style">{item.tenant_id}</div>
                        </div>
                        <div className="col-auto">
                          <div
                            className="text-style-bold"
                            style={{ textAlign: "right" }}
                          >
                            {item.amount}
                          </div>
                          <div className="subtext-style">
                            {date.toLocaleString("default", { month: "short" })}{" "}
                            {date.getDate()}, {date.getFullYear()}{" "}
                            {date.toTimeString().slice(0, 5)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              );
            })}
          </CardBody>
        </div>
      );
    } else {
      return (
        <Form className="border-top" onSubmit={onSubmit}>
          <Card.Body>
            <h2 className="card-heading">Redeem Voucher</h2>
            <Form.Group controlId="name">
              <Form.Label>Your Full Name</Form.Label>
              <Form.Control
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                isInvalid={!!errors.fullName}
              />
              {errors.fullName && (
                <Form.Control.Feedback type="invalid">
                  {errors.fullName}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Card.Body>
{/* 
          <Card.Body>
            <Form.Group controlId="email">
              <Form.Label>Your Email ID</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!errors.email}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Card.Body> */}

          <Card.Body>
            <Form.Group controlId="number">
              <Form.Label>Mobile Number</Form.Label>
              <InputGroup>
                <InputGroup.Text>+91</InputGroup.Text>
                <Form.Control
                  type="text"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  isInvalid={!!errors.number}
                />
                {errors.number && (
                  <Form.Control.Feedback type="invalid">
                    {errors.number}
                  </Form.Control.Feedback>
                )}
              </InputGroup>


            </Form.Group>
          </Card.Body>



          <CardBody className="card-btn">
            <div className="card-btn1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="#0F62EA"
                className="bi bi-exclamation-triangle"
                viewBox="0 0 16 16"
                style={{
                  marginBottom: "1%",
                }}
              >
                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
              </svg>
              <strong
                style={{
                  color: "#0F62EA",
                  textTransform: "uppercase",
                  fontSize: "1.1rem",
                }}
              >
                {" "}
                Important Notice
              </strong>
              <br />
              <p style={{ margin: "2% 0 0 0" }}>
                You must have an UPI app associated with this mobile number to
                receive activated e₹UPI Prepaid e-Voucher. The eRupi will
                reflect in the UPI app associated with this mobile number.
              </p>
            </div>
            <i className="bi bi-exclamation-triangle"></i>
          </CardBody>

          <CardBody >
            <Form.Group controlId="checked">
              <Form.Check
                type="checkbox"
                label="I have read the Terms & Conditions and agree to redeem
          this voucher to issue an e₹UPI Prepaid e-Voucher against
          the entered mobile number"

                value={checked}
                onChange={(e) => {
                  if (e.target.value === "true") {
                    setChecked(false)
                  }
                  if (e.target.value === "false") {
                    setChecked(true)
                  }
                  e.target.classList.remove('is-invalid')
                }}
                className="hollow-checkbox"
              >

              </Form.Check>




            </Form.Group>

          </CardBody>


          <CardBody className="card-button">
            <PrimaryButton variant="primary" type="submit">
              Get e₹UPI Prepaid eVoucher
            </PrimaryButton>
          </CardBody>
        </Form>
      );
    }
  };

  return (
    <Card className="voucher-detail-card">
      <div className="position-relative">
        <Card.Img variant="top" src={voucher.program.voucher_image} />
        {/* <Image
          className="position-absolute top-0 start-0 program-logo"
          src={voucher.program.logo}
        /> */}
      </div>

      <div>
        <CardBody className="text-center amount">
          <h4>₹{voucher.final_balance}</h4>
          <h6>
            Expiring on{" "}
            {/* {calculateDaysDifferenceBetweenDates(
              new Date(voucher.createdAt),
              date
            )}{" "}
            days on  */}
            {date.toLocaleString("default", { month: "short" })}{" "}
            {date.getDate()}, {date.getFullYear()}
          </h6>
        </CardBody>

        {card_body()}
      </div>
    </Card>
  );
}

function calculateDaysDifferenceBetweenDates(date1, date2) {
  // Ensure both inputs are Date objects
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    throw new Error("Both arguments must be Date objects.");
  }

  // Calculate the difference in time (in milliseconds)
  const timeDifference = date2.getTime() - date1.getTime();

  // Convert the time difference from milliseconds to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // Return the difference as a string
  return daysDifference;
}

export default VoucherDetailCard;
